import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useLayoutEffect } from "react";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
} from "./pages/auth";
// import { AdminDashboard, SuperAdminDashboard } from "./pages/dashboard";
import { useAuthContext } from "./hooks/useAuthContext";
import useSignIn from "./hooks/useSignIn";
import { Spinner } from "./components/shared";

// layout routes
import { Layout, AuthLayout } from "./components/layout";
import PrivateRoute from "./routes/PrivateRoute";

const Error404 = lazy(() => import("./pages/Error404"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
// alarms
const Alarms = lazy(() => import("./pages/alarms/Alarms"));

// admin views
const AddAdmin = lazy(() => import("./pages/administrator/AddAdmin"));
const AdminDetails = lazy(() => import("./pages/administrator/AdminDetails"));
const Admin = lazy(() => import("./pages/administrator/Admin"));

// company views
const Company = lazy(() => import("./pages/company/Company"));
const CompanyDetails = lazy(() => import("./pages/company/CompanyDetails"));
const AddCompany = lazy(() => import("./pages/company/AddCompany"));

// management views
const Management = lazy(() => import("./pages/management/Management"));
const UserDetails = lazy(() => import("./pages/management/users/UserDetails"));
const RoleDetails = lazy(() => import("./pages/management/roles/RoleDetails"));
const CreateRole = lazy(() => import("./pages/management/roles/CreateRole"));
const CreateUser = lazy(() => import("./pages/management/users/CreateUser"));

// device views
const Devices = lazy(() => import("./pages/devices/Devices"));
const AddDevice = lazy(() => import("./pages/devices/AddDevice"));
const DeviceDetails = lazy(() => import("./pages/devices/DeviceDetails"));

// site views
const Sites = lazy(() => import("./pages/sites/Sites"));
const CreateSite = lazy(() => import("./pages/sites/CreateSite"));
const SiteDetails = lazy(() => import("./pages/sites/SiteDetails"));
const SiteData = lazy(() => import("./pages/sites/SiteData"));
const SiteDataChart = lazy(() => import("./pages/sites/SiteDataChart"));

function App() {
  const { isAuthenticated } = useAuthContext();
  const { fetchLoggedInUser } = useSignIn();

  useLayoutEffect(() => {
    if (window.location.pathname !== "/login") {
      fetchLoggedInUser();
    } else {
      if (localStorage.getItem("user")) {
        fetchLoggedInUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="w-full flex items-center justify-center">
            <Spinner lg />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/login"
            element={
              <AuthLayout title="Login" subtitle="Enter Company Login Details">
                <Login />
              </AuthLayout>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <AuthLayout
                title="Reset your password"
                subtitle="Enter your email to start the process"
              >
                <ForgotPassword />
              </AuthLayout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <AuthLayout
                title="Create New password"
                subtitle="Create a new password and you are all set"
              >
                <ResetPassword />
              </AuthLayout>
            }
          />
          <Route path="/verify-email" element={<VerifyEmail />} />

          <Route element={<Layout />}>
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/sites" element={<Sites />} />
              <Route path="/sites/create" element={<CreateSite />} />
              <Route path="/sites/:id" element={<SiteDetails />} />
              <Route path="/sites/device-history/:id" element={<SiteData />} />
              <Route
                path="/sites/device-history-chart/:id"
                element={<SiteDataChart />}
              />
              <Route path="/management" element={<Management />} />
              <Route path="/management/create-user" element={<CreateUser />} />
              <Route path="/management/create-role" element={<CreateRole />} />
              <Route
                path="/management/user-details/:id"
                element={<UserDetails />}
              />
              <Route
                path="/management/role-details/:id"
                element={<RoleDetails />}
              />
              <Route path="/devices/add" element={<AddDevice />} />
              <Route path="/devices" element={<Devices />} />
              <Route path="/devices/:id" element={<DeviceDetails />} />
              <Route path="/company" element={<Company />} />
              <Route path="/company/add" element={<AddCompany />} />
              <Route path="/company/:id" element={<CompanyDetails />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/add" element={<AddAdmin />} />
              <Route path="/admin/:id" element={<AdminDetails />} />
              <Route path="/alarms" element={<Alarms />} />
            </Route>
          </Route>
          <Route path="/404" element={<Error404 />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
