import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyService from "../services/company";
import AuthService from "../services/auth";
import { errorToast, success } from "../components/shared";

const useFetchCompanies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const [error, setError] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [companiesOverview, setCompaniesOverview] = useState(null);
  const [companyStats, setCompanyStats] = useState(null);
  const navigate = useNavigate();


  const fetchCompanies = async () => {
    setIsLoadingCompanies(true);
    setError(null);

    try {
      const res = await CompanyService.getCompanies();
      const { data } = res.data;
      //   success(msg);
      setIsLoadingCompanies(false);
      setCompanies(data);
    } catch (error) {
      setIsLoadingCompanies(false);
      setError(error);
      errorToast(error.message);
    }
  };
   const fetchCompanyStats = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await CompanyService.getCompanyStats();
      const { data } = res.data;
      setIsLoading(false);
      setCompanyStats(data);
    } catch (error) {
      setIsLoading(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const fetchCompanyById = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await CompanyService.getCompanyById(id);
      const { data } = res.data;
      //   success(msg);
      setCompany(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const fetchCompanyByName = async (companyName) => {
    setIsLoadingCompanies(true);
    setError(null);
    let name = String(companyName).toLowerCase();

    try {
      const res = await CompanyService.getCompanyByName(name);
      const { data, type, msg } = res.data;
      if (type === "success") {
        setCompanies(data);
        setIsLoadingCompanies(false);
      }
      if (type === "error") {
        errorToast(msg);
        setIsLoadingCompanies(false);
      }

      setIsLoadingCompanies(false);
    } catch (error) {
      setIsLoadingCompanies(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const filterCompaniesByStatus = async (active) => {
    setIsLoadingCompanies(true);
    setError(null);

    try {
      const res = await CompanyService.filterCompanyByStatus(active);
      const { data } = res.data;
      //   success(msg);
      setCompanies(data);
      setIsLoadingCompanies(false);
    } catch (error) {
      setIsLoadingCompanies(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const toggleCompanyStatus = async (companyId) => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await CompanyService.toggleCompanyStatus(companyId);
      const { msg, type } = res.data;
      if (type === "success") {
        success(msg);
        navigate("/company")
      }
      if (type === "error") {
        errorToast(msg);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const fetchCompaniesOverview = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await CompanyService.getCompaniesOverview();

      const { data } = res.data;
      //   success(msg);
      setCompaniesOverview(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const createCompany = async (company) => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await CompanyService.createCompany(company);
      const { msg, status } = res.data;
      success(msg);
      setIsLoading(false);
      return status;
    } catch (error) {
      setIsLoading(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const updateCompany = async (company) => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await CompanyService.updateCompany(company);
      const { msg, status } = res.data;
      success(msg);
      setIsLoading(false);
      return status;
    } catch (error) {
      setIsLoading(false);
      setError(error);
      errorToast(error.message);
    }
  };
  const checkCompany = async (companySlug) => {
    const development = "localhost";
    const productionOwner = "vertgrid";

    if (companySlug.includes(development)|| companySlug.includes(productionOwner)) {
      return ;
    }
    setIsLoading(true);
    setError(null);
    try {
      const res = await AuthService.checkCompany(companySlug);
      const { type, data} = res.data;
      setIsLoadingUrl(false);
      if (type === "success" && data.active ) {
        localStorage.setItem("company", JSON.stringify(data));
        return true;
      } else {
        localStorage.clear();
        navigate("/404");
      }
    } catch (error) {
      setIsLoadingUrl(false);
      setError(error);
      errorToast(error.message);
    }
  };

  return {
    isLoading,
    isLoadingUrl,
    isLoadingCompanies,
    error,
    createCompany,
    updateCompany,
    checkCompany,
    fetchCompanies,
    fetchCompanyById,
    fetchCompaniesOverview,
    filterCompaniesByStatus,
    fetchCompanyByName,
    toggleCompanyStatus,
    fetchCompanyStats,
    companies,
    company,
    companiesOverview,
    companyStats
  };
};

export default useFetchCompanies;
