import React, { useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";

const Dropdown = ({ children, icon, width, full }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const closeDropdown = () => {
    setOpen(false);
  };
  const ref = useOutsideClick(closeDropdown);
  return (
    <div ref={ref} className="relative ">
      <div
        className={`"cursor-pointer p-2 ${full ? "w-full" : "w-fit mx-auto"}`}
        onClick={toggleOpen}
        // onBlur={() => setOpen(false)}
      >
        {icon}
      </div>
      {open && (
        <div
          className={`bg-white mt-2 border border-cardBorder text-black text-md text-start p-2 z-[50] absolute right-0 top-[40%]  rounded min-w-[5rem] shadow-customTwo  ${
            width ? "width" : "w-fit"
          }  cursor-pointer `}
        >
          <div >{children}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
