import DashboardIcon from "./dashboard.svg";
import DashboardActiveIcon from "./dashboard-active.svg";
import SitesIcon from "./sites.svg";
import SitesActiveIcon from "./sites-active.svg";
import AlarmIcon from "./alarms.svg";
import AlarmActiveIcon from "./alarms-active.svg"
import ManagementIcon from "./management.svg";
import ManagementActiveIcon from "./management-active.svg";
import DeviceIcon from "./devices.svg";
import DeviceActiveIcon from "./devices-active.svg";
import CompanyIcon from "./company.svg";
import CompanyActiveIcon from "./company-active.svg";
import AdminIcon from "./admin.svg";
import AdminActiveIcon from "./admin-active.svg";
import SettingsIcon from "./settings.svg";
import LogoutIcon from "./logout.svg";
import EmailIcon from "./email.svg";
import PasswordIcon from "./password.svg";
import AlertIcon from "./alert.svg";
import DateIcon from "./date-icon.svg";
import FilterIcon from "./filter.svg";
import PVEnergyIcon from "./pv-energy-icon.svg";
import BatteryTempIcon from "./battery-temp.svg";
import CabinetTempIcon from "./cabinet-temp.svg";
import RolesIcon from "./roles.svg";
import RolesActiveIcon from "./roles-active.svg";
import UsersIcon from "./users.svg";
import UsersActiveIcon from "./users-active.svg";
import LocationIcon from "./location.svg";
import LocationActiveIcon from "./location-active.svg";
import BatteryFlow from "./battery.svg";
import PlugspotFlow from "./plugspot.svg";
import PanelFlow from "./panel.svg";
import Avatar from "./avatar.svg";
import EyeSlash from "./eyeslash.svg";
import MobileNavIcon from "./mobile-nav-icon.svg";
import DropdownIcon from "./dropdown-icon.svg"
import CheckIcon from "./check.svg"

export {
  Avatar,
  DashboardIcon,
  DashboardActiveIcon,
  SitesIcon,
  SitesActiveIcon,
  AlarmIcon,
  AlarmActiveIcon,
  ManagementIcon,
  ManagementActiveIcon,
  DeviceIcon,
  DeviceActiveIcon,
  CompanyIcon,
  CompanyActiveIcon,
  AdminIcon,
  AdminActiveIcon,
  SettingsIcon,
  LogoutIcon,
  EmailIcon,
  PasswordIcon,
  AlertIcon,
  DateIcon,
  FilterIcon,
  PVEnergyIcon,
  CabinetTempIcon,
  BatteryTempIcon,
  RolesIcon,
  RolesActiveIcon,
  UsersIcon,
  UsersActiveIcon,
  LocationIcon,
  LocationActiveIcon,
  BatteryFlow,
  PlugspotFlow,
  PanelFlow,
  EyeSlash,
  MobileNavIcon,
  DropdownIcon,
  CheckIcon
};
