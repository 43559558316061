import axios from "axios";
import AuthService from "./auth";
import authHeader from "./auth-header";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers["Authorization"] = authHeader();
    // config.headers["Content-Type"] = "application/json";
    // }
    return config;
  },
  function (error) {
    // if(error.status === 401 || 403){
    //   window.location.replace("/login");
    // }
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && (error.response.status === 401 || 403)) {
      console.log(error.response, "axios err");
        AuthService.logout();
        window.location.replace("/login");
      // }
    }
    return Promise.reject(error);
  }
);

export default instance;
