import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth";
import { useAuthContext } from "./useAuthContext";
import { success, errorToast } from "../components/shared";
import { usePermify } from "@permify/react-role";

const useSignIn = () => {
  const navigate = useNavigate();
  const { setUser } = usePermify();
  const { dispatch } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isAborted, setIsAborted] = useState(false);
  const [error, setError] = useState(null);
  var subdomain = window.location.host.split(".")[0];
  const productionOwner = "vertgrid";
  const development = "localhost";

  const signIn = async (userdata) => {
    setIsLoading(true);
    setError(null);

    try {
      const company = JSON.parse(localStorage.getItem("company"))
      userdata = { ...userdata, ...{ companyId: company?.companyId } }
      const res = await AuthService.login(userdata);
      const { type, errors, msg } = res;

      if (type === "success") {
        try {
          const userdata = await AuthService.getloggedInUserDetails();
          const { data } = userdata;
          dispatch({ type: "LOGIN", payload: data });
          const obj = {
            id: data.userId,
            roles: [data._user.role],
            permissions: data.permissions,
          };
          setUser(obj);
          if (data._user.role === "owner") {
            if (
              subdomain.includes(development) ||
              subdomain.includes(productionOwner)
            ) {
              navigate("/dashboard");
              success("Login Successful");
            } else {
              dispatch({ type: "LOGOUT" });
              navigate("/login");
              errorToast("You do not have access to this domain");
            }
          }
          if (data._user.role !== "owner") {
            console.log(data._user.role, "not owner");
            if (
              subdomain.includes(development) ||
              subdomain.includes(productionOwner)
            ) {
              dispatch({ type: "LOGOUT" });
              navigate("/login");
              errorToast("You do not have access to this domain");
            } else {
              navigate("/dashboard");
              success("Login Successful");
            }
          }
          setIsLoading(false);
        } catch (error) {
          errorToast(error.message);
        }
      }
      if (type === "error") {
        console.log("error", errors);
        errorToast(msg);
        errorToast(errors[0]);
        setIsLoading(false);
        if (errors?.length > 0 && errors[0] === "Email is not verified") {
          navigate("/verify-email?token=j972efha87yucghaygwhud");
        }
      }
      setIsLoading(false);

      if (!isAborted) {
        setIsLoading(false);
        setError(null);
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.message);

      if (!isAborted) {
        console.log(error, "error");
        setIsLoading(false);
      }
    }
  };
  const fetchLoggedInUser = async () => {
    try {
      const userdata = await AuthService.getloggedInUserDetails();
      const { data } = userdata;
      dispatch({ type: "LOGIN", payload: data });
      const obj = {
        id: data.userId,
        roles: [data._user.role],
        permissions: data.permissions,
      };
      setUser(obj);
      setIsLoading(false);
    } catch (error) {
      console.log("authentication failed. Please login");
    }
  };
  const verifyEmail = async (token) => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await AuthService.verifyEmail(token);
      const { type } = res;
      if (type === "success") {
        return true;
      }
      if (type === "error") {
        return false;
      }
      setIsLoading(false);
    } catch (error) {
      errorToast(error?.response?.data?.message || error.message);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const resendEmail = async () => {
    try {
      const res = await AuthService.resendEmail();
      const { type, msg } = res;
      if (type === "success") {
        success(msg);
      }
      if (type === "error") {
        errorToast(msg);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsAborted(true);
    };
  }, []);

  return {
    isLoading,
    error,
    signIn,
    fetchLoggedInUser,
    verifyEmail,
    resendEmail,
  };
};

export default useSignIn;
