import React from 'react'

const PageTitle = ({title}) => {
  return (
    <div className='w-full border-b border-b-grey3 py-3'>
        <h4 className='font-semibold text-grey2 text-xl md:text-2xl lg:text-3xl'>{title}</h4>
    </div>
  )
}

export default PageTitle