import React from "react";

const DashboardCard = ({ title, value, image, color, bg }) => {
  return (
    <div
      className={` ${
        color ? `text-${color}` : ""
      } flex items-center justify-between p-5 border border-grey4 rounded`}
      style={{ backgroundColor: `${bg ? bg : ""}` }}
    >
      <div className="flex flex-col justify-between">
        <h6 className="text-lg font-semibold mb-3">{title}</h6>
        <h6 className="text-2xl font-bold">{value}</h6>
      </div>
      <div>
        <img src={image} alt="" className="w-20 h-20" />
      </div>
    </div>
  );
};

export default DashboardCard;
