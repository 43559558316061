import React from "react";
import Checkbox from "react-custom-checkbox";
import {ReactComponent as Check} from "../../assets/icons/check.svg"

const CustomCheckbox = ({ handleChange, label, name,value, className, bgColor, checkColor, labelStyle, checked }) => {
  return (
    <>
      <Checkbox
      checked={checked}
        icon={
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: "#E9F6FB",
              alignSelf: "stretch",
            }}
          >
            <Check color="#1FA1D4" className="w-3 h-3" />
          </div>
        }
        name={name}
        value={value}
        className={className}
        onChange={handleChange}
        borderColor="#1FA1D4"
        borderWidth={1}
        borderRadius={2}
        style={{ overflow: "hidden" }}
        size={15}
        label={label}
        labelStyle={labelStyle}
      />
    </>
  );
};

export default CustomCheckbox;
