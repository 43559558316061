import axios from "axios";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";
let baseurl = process.env.REACT_APP_BACKEND_URL;
const AuthService = {
  login: async (data) => {
    return await axios.post(`${baseurl}/login`, data).then((res) => {
      // console.log("res", res);
      if (res.data?.data?.token) {
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data));
      }
      return res.data;
    });
  },
  //   forgotPassword: async (email) => {
  //     let baseurl = process.env.REACT_APP_BACKEND_URL;
  //     return await axios.post(`${baseurl}/api/admin/v1/auth/forgot/init`, { email }).then((res) => {
  //       return res;
  //     });
  //   },
  //   resetPassword: async (email, token, password) => {
  //     let baseurl = process.env.REACT_APP_BACKEND_URL;
  //     return await axios.post(`${baseurl}/api/admin/v1/auth/forgot/complete`, { email, token, password }).then((res) => {
  //       return res;
  //     });
  //   },
  //   changePassword: async (obj) => {
  //     let baseurl = process.env.REACT_APP_BACKEND_URL;
  //     return await axios
  //       .post(`${baseurl}/api/admin/v1/app/settings/change_password`, obj, {
  //         headers: authHeader(),
  //       })
  //       .then((res) => {
  //         checkToken(res);
  //         return res;
  //       });
  //   },
  getloggedInUserDetails: async () => {
    return await axios
      .get(`${baseurl}/user/get/me`, {
        headers: { Authorization: authHeader() },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
  verifyEmail: async (token) => {
    return await axios
      .patch(`${baseurl}/user/verifyEmail/${token}`)
      .then((res) => {
        return res.data;
      });
  },
  resendEmail: async (email) => {
    return await axios
      .get(`${baseurl}/user/verifyEmail/${email}`)
      .then((res) => {
        return res.data;
      });
  },
  checkCompany: async (companyName) => {
    return await axios
      .get(`${baseurl}/company/exist/${companyName}`)
      .then((res) => {
        return res;
      });
  },
  getUser: () => {
    let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    return user;
  },
  logout: () => {
    window.localStorage.clear();
  },
};

export default AuthService;
