import { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import AuthService from "../services/auth";
import { useAuthContext } from "./useAuthContext";

const useLogOut = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAborted, setIsAborted] = useState(false);
  const [error, setError] = useState(null);
  const { dispatch} = useAuthContext();

  const logout = () => {
    setIsLoading(true);
    setError(null);

    try {
      AuthService.logout();

      dispatch({ type: "LOGOUT" });
      navigate(`/login`);

      if (!isAborted) {
        setIsLoading(false);
        setError(null);
        navigate(`/login`);
      }
    } catch (error) {
      if (!isAborted) {
        setError(error);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    return () => {
      setIsAborted(true);
    };
  }, [isAborted]);

  return { isLoading, error, logout };
};

export default useLogOut;
