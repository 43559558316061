import Logo from "./logo.svg";
import LogoTransparent from "./logo-transparent.svg";
import AuthImage from "./auth-image.jpeg";
import PlugSpot from "./plugspot-image.png";
import ActiveSites from "./active-sites.svg";
import InactiveSites from "./inactive-sites.svg";
import TotalSitesAdmin from "./total-sites.svg";
import TotalSitesSuperAdmin from "./total-sites-superadmin.svg";
import TotalCompanies from "./total-companies.svg";
import DeviceCardImage from "./device-card-image.svg"
import SiteDataGif from "./battery-full.gif"
import SiteDataGif2 from "./one-load-off.gif"

export {
  Logo,
  LogoTransparent,
  AuthImage,
  PlugSpot,
  ActiveSites,
  InactiveSites,
  TotalCompanies,
  TotalSitesAdmin,
  TotalSitesSuperAdmin,
  DeviceCardImage,
  SiteDataGif,
  SiteDataGif2,
};
