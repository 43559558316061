import React, { forwardRef } from "react";

const Input = forwardRef(function Input(
  {
    type,
    placeholder,
    name,
    id,
    disabled,
    value,
    additionalFunc,
    onChange,
    onKeyDown,
    onKeyUp,
    className,
    paddingX,
    paddingY,
    fontSize,
    defaultValue,
    error,
    ...props
  },
  ref
) {
  return (
    <React.Fragment>
      <input
        className={`bg-input w-full  px-4 ${
          error ? "focus:outline-red" : "focus:outline-teal1"
        }  ${paddingY ? paddingY : "py-3"}
       ${paddingX ? paddingX : "px-4"}
      ${fontSize? fontSize : 'text-sm'}
      ${disabled? 'bg-transparent': 'bg-input'}
      focus:bg-transparent ${className}`}
        type={type}
        placeholder={placeholder}
        id={id}
        name={name}
        ref={ref}
        disabled={disabled}
        onChange={(e) => {
          onChange(e);
          additionalFunc && additionalFunc(e);
        }}
        onKeyDown={onKeyDown}
        value={value}
        onKeyUp={onKeyUp}
        defaultValue={defaultValue}
        {...props}
      />
    </React.Fragment>
  );
});
export default Input;
