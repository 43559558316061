import { useState, useLayoutEffect } from "react";
import { EmailIcon, PasswordIcon, EyeSlash } from "../../assets/icons";
import { Input } from "../../components/shared";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useSignIn from "../../hooks/useSignIn";
import useFetchCompanies from "../../hooks/useFetchCompanies";
import { Spinner } from "../../components/shared";


const Login = () => {
  const { isLoading, signIn } = useSignIn();
  const [passState, setPassState] = useState(false);
  const { checkCompany } = useFetchCompanies();

  var subdomain = window.location.host.split(".")[0];


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    signIn(data);
  };

  const ifCompanyExists = async (subdomain) => {
    const result = await checkCompany(subdomain);
    return result;
  };


  useLayoutEffect(() => {
    ifCompanyExists(subdomain);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdomain]);

  return (
    <>
      <form className="mt-5 " onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 relative">
          <img
            src={EmailIcon}
            alt=""
            className="w-5 h-5 absolute left-3 top-1/4 "
          />
          <Input
            placeholder=" "
            name="email"
            className="rounded-xl pl-12 "
            {...register("username", {
              required: { value: true, message: "This field is required" },
            })}
            error={errors.username}
          />
          <label
            htmlFor="email"
            className={` ${
              errors?.username?.message ? "text-red" : "text-grey1"
            } custom-label`}
          >
            {errors?.username?.message ? errors.username.message : "Email"}
          </label>
        </div>
        <div className="mb-4 relative ">
          <img
            src={PasswordIcon}
            alt=""
            className="w-5 h-5 absolute left-3 top-1/3 "
          />
          <span className="absolute right-3 top-1/3">
            <img
              src={EyeSlash}
              alt=""
              className="w-4 h-4"
              onClick={() => setPassState(!passState)}
            />
          </span>

          <Input
            placeholder=" "
            name="password"
            type={passState ? "text" : "password"}
            className="rounded-xl pl-12"
            {...register("password", {
              required: { value: true, message: "This field is required" },
            })}
            error={errors.password}
          />
          <label
            htmlFor="password"
            className={` ${
              errors?.password?.message ? "text-red" : "text-grey1"
            } custom-label`}
          >
            {errors?.password?.message ? errors.password.message : "Password"}
          </label>
        </div>
        <div className="text-grey1 flex items-center">
          <input
            type="checkbox"
            className="border border-grey1 text-sm w-4 h-4"
          />
          <span className="ml-3 text-sm">Remember me</span>
        </div>
        <div className="text-center mt-8">
          <button
            className={`${
              isLoading ? "bg-teal1/40" : "bg-teal1"
            } text-white text-2xl w-4/5 mx-auto py-3 rounded-xl`}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="w-fit mx-auto">
                <Spinner white />
              </div>
            ) : (
              "Log In"
            )}
          </button>
          <Link
            to="/forgot-password"
            className=" block text-grey2 text-sm mt-3 "
          >
            Forgot Password?
          </Link>
        </div>
      </form>
    </>
  );
};

export default Login;
