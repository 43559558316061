import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AuthContextProvider } from "./context/AuthContext";
import { PermifyProvider } from "@permify/react-role";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PermifyProvider>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
          <ToastContainer
            autoClose={2500}
            draggable={true}
            hideProgressBar={false}
            position={"top-right"}
            theme={"light"}
            newestOnTop
          />
        </BrowserRouter>
      </AuthContextProvider>
    </PermifyProvider>
  </React.StrictMode>
);
