import axios from "./axios";
let baseurl = process.env.REACT_APP_BACKEND_URL;

const CompanyService = {
  getCompanies: async () => {
    return await axios
      .get(`${baseurl}/companies`)
      .then((res) => {
        // checkToken(res);
        return res;
      });
  },
  getCompanyById: async (id) => {
    return await axios
      .get(`${baseurl}/company/${id}`)
      .then((res) => {
        return res;
      });
  },
  getCompanyByName: async (companyName) => {
    return await axios
      .get(`${baseurl}/company/filter/name/${companyName}`)
      .then((res) => {
        return res;
      });
  },
  filterCompanyByStatus: async (active) => {
    return await axios
      .get(`${baseurl}/company/filter/active/${active}`)
      .then((res) => {
        return res;
      });
  },
  toggleCompanyStatus: async (data) => {
    return await axios
      .patch(`${baseurl}/company/activate`, data)
      .then((res) => {
        return res;
      });
  },
  getCompaniesOverview: async()=> {
    return await axios
    .get(`${baseurl}/company/overview/all`)
    .then((res) => {
      return res;
    });
  },
  getCompanyStats: async()=> {
    return await axios
    .get(`${baseurl}/company/dashboard/stat`)
    .then((res) => {
      return res;
    });
  },
  createCompany: async (company) => {
    return await axios.post(`${baseurl}/company`, company).then((res) => {
      // checkToken(res);
      return res;
    });
  },
  updateCompany: async (company) => {
    return await axios.patch(`${baseurl}/company`, company).then((res) => {
      // checkToken(res);
      return res;
    });
  },
  
};

export default CompanyService;
