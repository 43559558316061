import React from "react";
import { NavLink } from "react-router-dom";
import { sidebaritems, ownersidebaritems } from "../../data";
import { LogoutIcon } from "../../assets/icons";
import useLogOut from "../../hooks/useLogOut";
import { HasAccess } from "@permify/react-role";
import { useAuthContext } from "../../hooks/useAuthContext";
export const SidebarItem = ({ item }) => {
  let activeClassName = "block bg-white px-6 py-2 w-full text-darkTeal";
  let inactiveClassName =
    "block bg-transparent px-6 py-2 w-full underline-none text-white";
  return (
    <NavLink
      to={item.link}
      className={({ isActive }) =>
        isActive ? activeClassName : inactiveClassName
      }
    >
      {({ isActive }) => {
        return (
          <div className="flex items-center my-4 ">
            <img
              src={isActive ? item.activeIcon : item.icon}
              alt="home"
              className=" w-5 h-5 mr-4"
            />
            <span className="font-semibold text-lg">{item.name}</span>
          </div>
        );
      }}
    </NavLink>
  );
};

const Sidebar = () => {
  const {
    user: { _user },
  } = useAuthContext();
  const { logout } = useLogOut();
  const onLogout = () => {
    logout();
  };


  return (
    <div className="w-fit relative ">
      <div className="hidden lg:block sidebar">
        <div className=" overflow-y-auto">
          {_user?.role === "owner" ? (
            <div className="">
              {ownersidebaritems.map((item, i) => (
                <HasAccess
                  key={`djkbh${i}`}
                  roles={"owner"}
                  permissions={item.permissions}
                  renderAuthFailed={null}
                  // isLoading={<Spinner/>}
                >
                  <SidebarItem item={item} />
                </HasAccess>
              ))}
            </div>
          ) : (
            <div className="">
              {sidebaritems.map((item, i) => (
                <HasAccess
                  key={`djkbh${i}`}
                  // roles={"owner"}
                  permissions={item.permissions}
                  renderAuthFailed={null}
                  // isLoading={<Spinner/>}
                >
                  <SidebarItem item={item} />
                </HasAccess>
              ))}
            </div>
          )}

          <div className="mt-12 ">
            <div
              className=" w-full flex items-center my-4 px-6 py-2 cursor-pointer "
              onClick={onLogout}
            >
              <img src={LogoutIcon} alt="home" className=" w-5 h-5 mr-4" />
              <p className="font-semibold text-lg text-white">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
