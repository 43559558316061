import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as CaretDown } from "../../assets/icons/caret-down.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

const Select = ({
  selectKey,
  viewKey,
  data,
  width,
  fullWidth,
  //   valHandle,
  onChange,
}) => {
  const [selected, setSelected] = useState("");

  const handleChange = (val) => {
    setSelected(val);
    onChange(val);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setSelected(data[0]);
  }, [data]);

  return (
    <div>
      <Listbox value={selected} onChange={handleChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default bordered-select rounded-lg h-10 flex items-center">
            <span className="block truncate pr-3 capitalize">
              {selected?.[viewKey] || ""}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
              <CaretDown className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute mt-1 max-h-60 ${
                fullWidth ? "w-full" : width ? width : "w-40"
              } overflow-auto rounded-md bg-white py-1 shadow-lg `}
            >
              {data.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-8 pr-4 ${
                      active ? "bg-input text-dark" : "text-dark"
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate capitalize ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {item?.[selectKey]}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                          <CheckIcon className="h-3 w-3" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Select;
