import { Suspense } from "react";
import { Navbar, Footer, Sidebar, Spinner } from "../shared";
import { Outlet } from "react-router-dom";

const Layout = ({ children }) => {
  return (
    <div className="relative flex flex-col justify-between h-[100vh] min-h-[100vh] ">
      <div> <Sidebar />
     
        <Navbar />

        <div className=" mt-20 lg:pl-[264px] w-full   py-6 px-4 md:p-6">
          <div className="h-full">
            {
              <Suspense
                fallback={
                  <div className="w-full flex items-center justify-center">
                    <Spinner lg />
                  </div>
                }
              >
                <Outlet />
              </Suspense>
            }{" "}
          </div>
        </div>
      </div>
      <div className=" lg:pl-[264px] w-full  ml-auto py-6 px-4 md:p-6 ">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
