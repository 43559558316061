import React from 'react'

const NoPermissions = () => {
  return (
    <div className='w-full flex items-center justify-center'>
        <h3 className='text-black font-bold text-2xl'>You do not have permission to view this page</h3>
    </div>
  )
}

export default NoPermissions;