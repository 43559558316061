import {
  DashboardIcon,
  DashboardActiveIcon,
  SitesIcon,
  SitesActiveIcon,
  AlarmIcon,
  ManagementIcon,
  ManagementActiveIcon,
  DeviceIcon,
  DeviceActiveIcon,
  CompanyActiveIcon,
  CompanyIcon,
  AdminActiveIcon,
  AdminIcon,
  SettingsIcon,
  AlarmActiveIcon,
} from "../assets/icons";

export const ownersidebaritems=[
  {
    name: "Dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
    link: "/dashboard",
    permissions: ["canViewDashboardCompany"],
  },
  {
    name: "Devices",
    icon: DeviceIcon,
    activeIcon: DeviceActiveIcon,
    link: "/devices",
    permissions: ["canViewDevice"],
  },
  {
    name: "Company",
    icon: CompanyIcon,
    activeIcon: CompanyActiveIcon,
    link: "/company",
    permissions: ["canViewCompany"],
  },
  {
    name: "Administrator",
    icon: AdminIcon,
    activeIcon: AdminActiveIcon,
    link: "/admin",
    permissions: ["canViewCompanyAdmin"],
  },
]

export const sidebaritems = [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
    link: "/dashboard",
    permissions: ["canViewDashboardGlobal"],
  },
  {
    name: "Sites",
    icon: SitesIcon,
    activeIcon: SitesActiveIcon,
    link: "/sites",
    permissions: ["canViewSite"],
  },
  { name: "Alarms", icon: AlarmIcon, activeIcon:AlarmActiveIcon, link: "/alarms", permissions:["canViewAlarms"] },
  {
    name: "Management",
    icon: ManagementIcon,
    activeIcon: ManagementActiveIcon,
    link: "/management",
    permissions: ["canViewUser"],
  },
  {
    name: "Devices",
    icon: DeviceIcon,
    activeIcon: DeviceActiveIcon,
    link: "/devices",
    permissions: ["canViewDevice"],
  },
  {
    name: "Company",
    icon: CompanyIcon,
    activeIcon: CompanyActiveIcon,
    link: "/company",
    permissions: ["canViewCompany"],
  },
  {
    name: "Administrator",
    icon: AdminIcon,
    activeIcon: AdminActiveIcon,
    link: "/admin",
    permissions: ["canViewCompanyAdmin"],
  },
];

export const sidebarsetting = {
  name: "Settings",
  icon: SettingsIcon,
  link: "/settings",
};

export const sitedata = [
  {
    id: 331,
    name: "Site A",
    status: "active",
    location: "Lekki Phase 1",
    duration: "2 weeks",
    cabinetLockStatus: "open",
    technician: "Ayoola mayowa",
  },
  {
    id: "342",
    name: "Site B",
    status: "inactive",
    location: "Lekki Phase 1",
    duration: "2 weeks",
    technician: "Ayoola mayowa",
  },
  {
    id: "ajgu280",
    name: "Site C",
    status: "active",
    location: "Lekki Phase 2",
    duration: "2 weeks",
    technician: "Ayoola mayowa",
  },
  {
    id: "adg208",
    name: "Site D",
    status: "inactive",
    location: "Lekki Phase 1",
    duration: "3 weeks",
    technician: "Ayoola mayowa",
  },
  {
    id: "adcyv24",
    name: "Site E",
    status: "active",
    location: "Lekki Phase 1",
    duration: "2 weeks",
    technician: "Ayoola mayowa",
  },
  {
    id: "dGGCD",
    name: "Site F",
    status: "active",
    location: "Lekki Phase 1",
    duration: "2 weeks",
    technician: "Ayoola mayowa",
  },
];

export const devicedata = [
  {
    serialNumber: "WHT98EN56",
    location: "Lagos",
    capacity: "100W",
  },
  {
    serialNumber: "PHT00EN59",
    location: "Lagos",
    capacity: "100W",
  },
  {
    serialNumber: "WOT98EN56",
    location: "Lagos",
    capacity: "100W",
  },
  {
    serialNumber: "WHT98EN56",
    location: "Lagos",
    capacity: "Medium",
  },
  {
    serialNumber: "QHT95EN56",
    location: "Lagos",
    capacity: "Medium",
  },
  {
    serialNumber: "SHT98EN09",
    location: "Lagos",
    capacity: "Medium",
  },
  {
    serialNumber: "WHT98EN98",
    location: "Lagos",
    capacity: "Medium",
  },
  {
    serialNumber: "WGT98IT56",
    location: "Lagos",
    capacity: "Medium",
  },
  {
    serialNumber: "WHT54EN56",
    location: "Lagos",
    capacity: "100W",
  },
];

export const superadmindashboarddata = [
  {
    companyName: "Labour Holdings",
    sites: 24,
    manager: "John Doe",
  },
  {
    companyName: "John Doe",
    sites: 57,
    manager: "John Doe",
  },
  {
    companyName: "John Doe",
    sites: 57,
    manager: "John Doe",
  },
  {
    companyName: "John Doe",
    sites: 57,
    manager: "John Doe",
  },
  {
    companyName: "John Doe",
    sites: 57,
    manager: "John Doe",
  },
  {
    companyName: "John Doe",
    sites: 57,
    manager: "John Doe",
  },
];

export const userdata = [
  {
    id: 24,
    firstName: "Ayo",
    lastName: "Makinde",
    role: "Admin",
    location: "Abule Egba",
    site: "All",
    email: "ayo@gmail.com",
    phone: "+234908327523574",
  },
  {
    id: 49,
    firstName: "Ayo",
    lastName: "Makinde",
    role: "Admin",
    location: "Abule Egba",
    site: "All",
    email: "ayo@gmail.com",
    phone: "+234908327523574",
  },
  {
    id: 43,
    firstName: "Ayo",
    lastName: "Makinde",
    role: "Admin",
    location: "Abule Egba",
    site: "All",
    email: "ayo@gmail.com",
    phone: "+234908327523574",
  },
  {
    id: 41,
    firstName: "Ayo",
    lastName: "Makinde",
    role: "Admin",
    location: "Abule Egba",
    site: "All",
    email: "ayo@gmail.com",
    phone: "+234908327523574",
  },
  {
    id: 39,
    firstName: "Ayo",
    lastName: "Makinde",
    role: "Admin",
    location: "Abule Egba",
    site: "All",
    email: "ayo@gmail.com",
    phone: "+234908327523574",
  },
  {
    id: 29,
    firstName: "Ayo",
    lastName: "Makinde",
    role: "Admin",
    location: "Abule Egba",
    site: "All",
    email: "ayo@gmail.com",
    phone: "+234908327523574",
  },
];

export const roledata = [
  {
    name: "Technician",
    type: "Admin",
    userStats: 80,
  },
  {
    name: "Manager",
    type: "Super Admin",
    userStats: 80,
  },
  {
    name: "Technician",
    type: "Admin",
    userStats: 90,
  },
  {
    name: "Technician",
    type: "Admin",
    userStats: 80,
  },
];

export const locationdata = [
  {
    location: "Abule Egba",
    manager: "Sule Gbadamosi",
    sites: 24,
  },
  {
    location: "Surulere",
    manager: "Sule Taiwo",
    sites: 24,
  },
  {
    location: "Gbagada",
    manager: "Megan Gbadamosi",
    sites: 24,
  },
  {
    location: "Abule Egba",
    manager: "Sule Gbadamosi",
    sites: 24,
  },
  {
    location: "Abule Egba",
    manager: "Sule Gbadamosi",
    sites: 24,
  },
  {
    location: "Abule Egba",
    manager: "Sule Gbadamosi",
    sites: 24,
  },
];

export const companydata = [
  {
    id: 20,
    name: "Abby Holdings",
    sites: 75,
    manager: "Oye Adu",
    adminEmail: "oye@gmail.com",
  },
  {
    id: 22,
    name: "Abby Holdings",
    sites: 75,
    manager: "Oye Adu",
    adminEmail: "oye@gmail.com",
  },
  {
    id: 24,
    name: "Abby Holdings",
    sites: 75,
    manager: "Oye Adu",
    adminEmail: "oye@gmail.com",
  },
  {
    id: 26,
    name: "Abby Holdings",
    sites: 75,
    manager: "Oye Adu",
    adminEmail: "oye@gmail.com",
  },
];

export const historyData = [

  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641abad07fe53b6a28941224",
    deviceHistoryId: "7c97e3d6-dcb8-5016-ae99-b262530c0ffc",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 422,
    loadBCurrent: 229,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679473360867,
    nextUpdateTime: 1679473660861,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641abc037fe53b6a28941229",
    deviceHistoryId: "5e4d7aca-a61a-57e0-9cc7-d43cfd809aa6",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 400,
    loadBCurrent: 180,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679473667763,
    nextUpdateTime: 1679473967757,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641abd367fe53b6a2894122d",
    deviceHistoryId: "0760da7f-3114-5471-aee3-9367f063b531",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 539,
    loadBCurrent: 297,
    loadBVoltage: 113,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679473974603,
    nextUpdateTime: 1679474274597,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641abe697fe53b6a28941231",
    deviceHistoryId: "73309452-9657-5a4e-ac6a-92b22f83a925",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 532,
    loadBCurrent: 313,
    loadBVoltage: 105,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679474281479,
    nextUpdateTime: 1679474581473,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641abf9c7fe53b6a28941235",
    deviceHistoryId: "e950d9f2-aebc-5393-b198-c80725bf0c53",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 467,
    loadBCurrent: 222,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679474588343,
    nextUpdateTime: 1679474888337,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac0cf7fe53b6a28941239",
    deviceHistoryId: "0c54cf18-b656-5dbe-8078-b1f58d725ebe",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 409,
    loadBCurrent: 208,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679474895202,
    nextUpdateTime: 1679475195196,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac2027fe53b6a2894123d",
    deviceHistoryId: "8ef1c529-5f36-56a1-8843-f97ae12751b9",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 335,
    loadBCurrent: 270,
    loadBVoltage: 203,
    loadCCurrent: 139,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679475202061,
    nextUpdateTime: 1679475502055,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac3347fe53b6a28941241",
    deviceHistoryId: "9fe34d87-5776-5a29-8b7d-ba7e16e2a95f",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 309,
    loadBCurrent: 64,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679475508960,
    nextUpdateTime: 1679475808954,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac4677fe53b6a28941245",
    deviceHistoryId: "387613f7-a1ef-5fcf-929a-e7a5fa77c61c",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 529,
    loadBCurrent: 291,
    loadBVoltage: 125,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679475815782,
    nextUpdateTime: 1679476115776,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac59a7fe53b6a28941249",
    deviceHistoryId: "3b86443e-a4aa-532f-880d-53e0f4ad31fa",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 336,
    loadBCurrent: 297,
    loadBVoltage: 203,
    loadCCurrent: 131,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679476122664,
    nextUpdateTime: 1679476422658,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac6cd7fe53b6a2894124d",
    deviceHistoryId: "8f4122c0-55ab-500f-bb62-b624c447f5ea",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 539,
    loadBCurrent: 288,
    loadBVoltage: 119,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679476429526,
    nextUpdateTime: 1679476729519,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac8007fe53b6a28941251",
    deviceHistoryId: "280dd392-2883-578f-a34e-4a7d5feab1fb",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 485,
    loadBCurrent: 230,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679476736425,
    nextUpdateTime: 1679477036419,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ac9337fe53b6a28941255",
    deviceHistoryId: "dac6d8be-9b25-577d-aac0-e2634375ff13",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 401,
    loadBCurrent: 199,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679477043280,
    nextUpdateTime: 1679477343274,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641aca667fe53b6a28941259",
    deviceHistoryId: "a03a0973-a9bc-5309-8762-ff58036f1e7b",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 486,
    loadBCurrent: 255,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679477350118,
    nextUpdateTime: 1679477650113,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641acb987fe53b6a2894125d",
    deviceHistoryId: "e5c13911-dc1e-5375-9de6-05e4c51abcbd",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 485,
    loadBCurrent: 239,
    loadBVoltage: 2,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679477656984,
    nextUpdateTime: 1679477956978,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641acccb7fe53b6a28941261",
    deviceHistoryId: "e7d63de1-9ba0-5ea3-a404-f68255951859",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 390,
    loadBCurrent: 155,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679477963843,
    nextUpdateTime: 1679478263837,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641acdfe7fe53b6a28941265",
    deviceHistoryId: "0e3e250c-a132-56d8-98e1-1ccb342b348e",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 394,
    loadBCurrent: 150,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679478270701,
    nextUpdateTime: 1679478570695,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641acf317fe53b6a28941269",
    deviceHistoryId: "28dee21e-c561-5ad2-8f5d-7837f3fec4a4",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 462,
    loadBCurrent: 213,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679478577563,
    nextUpdateTime: 1679478877557,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad0647fe53b6a2894126d",
    deviceHistoryId: "8634d8d2-21c8-5046-9061-a10775d421e6",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 337,
    loadBCurrent: 108,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679478884465,
    nextUpdateTime: 1679479184459,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad1977fe53b6a28941271",
    deviceHistoryId: "9a4d3a42-0705-5dc4-bbfd-7f709369d95c",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 309,
    loadBCurrent: 81,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679479191303,
    nextUpdateTime: 1679479491298,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad2ca7fe53b6a28941275",
    deviceHistoryId: "b6aeb2ac-de9b-57fc-a812-3ad76756b803",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 399,
    loadBCurrent: 157,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679479498222,
    nextUpdateTime: 1679479798216,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad3fd7fe53b6a28941279",
    deviceHistoryId: "c1b2bd0e-0ba1-5183-afa0-f612e2d0fa62",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 528,
    loadBCurrent: 262,
    loadBVoltage: 80,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679479805043,
    nextUpdateTime: 1679480105037,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad5307fe53b6a2894127d",
    deviceHistoryId: "681aed08-56de-5203-a557-5f1e1446b580",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 523,
    loadBCurrent: 255,
    loadBVoltage: 69,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679480112965,
    nextUpdateTime: 1679480412960,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad6627fe53b6a28941281",
    deviceHistoryId: "c475018e-b5b6-5cdf-b0c3-7beb0834b57e",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 394,
    loadBCurrent: 150,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679480418790,
    nextUpdateTime: 1679480718784,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad7957fe53b6a28941285",
    deviceHistoryId: "7ceb9f18-2a99-5090-9c6d-4fcd247068d4",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 6,
    loadBCurrent: 0,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679480725687,
    nextUpdateTime: 1679481025680,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad8c87fe53b6a28941289",
    deviceHistoryId: "3ec4c427-64dd-52db-8c60-c05f0807e27c",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 320,
    loadBCurrent: 115,
    loadBVoltage: 91,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679481032485,
    nextUpdateTime: 1679481332479,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ad9fb7fe53b6a2894128d",
    deviceHistoryId: "61e9c55f-8243-51c6-a13d-766e57fd2cc4",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 240,
    loadBCurrent: 14,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679481339329,
    nextUpdateTime: 1679481639323,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641adb2e7fe53b6a28941291",
    deviceHistoryId: "b551de5b-66c6-5b3d-bdab-02294479e80a",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 213,
    loadBCurrent: 0,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679481646183,
    nextUpdateTime: 1679481946177,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641adc617fe53b6a28941295",
    deviceHistoryId: "34c8736e-c682-5dc5-bfa0-892e0e0deabd",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 282,
    loadBCurrent: 44,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679481953028,
    nextUpdateTime: 1679482253021,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641add937fe53b6a28941299",
    deviceHistoryId: "79f99ddf-6af7-5b1d-aa4f-5d3d28f83b26",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 384,
    loadBCurrent: 120,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679482259925,
    nextUpdateTime: 1679482559919,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641adec67fe53b6a2894129d",
    deviceHistoryId: "1ce10ce0-1f5b-5350-be8f-61abf6afec19",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 282,
    loadBCurrent: 16,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679482566787,
    nextUpdateTime: 1679482866781,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641adff97fe53b6a289412a1",
    deviceHistoryId: "0206f2f6-ac4d-5416-8e15-dac235aeca71",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 443,
    loadBCurrent: 205,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679482873664,
    nextUpdateTime: 1679483173658,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae12c7fe53b6a289412a5",
    deviceHistoryId: "c4ed2695-3b6a-5bfc-bc53-5c84d0d8f2ea",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 315,
    loadBCurrent: 87,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679483180480,
    nextUpdateTime: 1679483480474,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae25f7fe53b6a289412a9",
    deviceHistoryId: "21ed119a-bbad-5d05-b835-4647db448a24",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 449,
    loadBCurrent: 191,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679483487366,
    nextUpdateTime: 1679483787360,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae3927fe53b6a289412ad",
    deviceHistoryId: "4a66644b-438d-5a24-957c-dd98b99b3530",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 464,
    loadBCurrent: 223,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679483794182,
    nextUpdateTime: 1679484094176,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae4c57fe53b6a289412b1",
    deviceHistoryId: "3dd3c89e-1fcd-5bb9-9112-2c9db08db59b",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 451,
    loadBCurrent: 191,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679484101183,
    nextUpdateTime: 1679484401177,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae5f77fe53b6a289412b5",
    deviceHistoryId: "6c2558dd-03c4-5053-9ef0-f3cd9da6cfa8",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 368,
    loadBCurrent: 122,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679484407904,
    nextUpdateTime: 1679484707898,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae72a7fe53b6a289412b9",
    deviceHistoryId: "a4347b3f-b421-5f89-9836-c110fe7c4692",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 456,
    loadBCurrent: 185,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679484714765,
    nextUpdateTime: 1679485014759,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae85d7fe53b6a289412bd",
    deviceHistoryId: "23a3711c-7364-5ad5-9cb5-b028ae8e8e5b",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 458,
    loadBCurrent: 198,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679485021603,
    nextUpdateTime: 1679485321597,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641ae9907fe53b6a289412c1",
    deviceHistoryId: "e91da68e-ce0c-5e8f-ad3c-fd9e55b6bf3a",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 386,
    loadBCurrent: 158,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679485328485,
    nextUpdateTime: 1679485628479,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641aeac37fe53b6a289412c5",
    deviceHistoryId: "67c4bac6-ca39-58d4-a1f0-56f1adb84712",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 370,
    loadBCurrent: 127,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679485635323,
    nextUpdateTime: 1679485935318,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641aebf67fe53b6a289412c9",
    deviceHistoryId: "cb6f25c5-ac30-550b-b117-f79e925808b7",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 407,
    loadBCurrent: 153,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679485942301,
    nextUpdateTime: 1679486242296,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641aed297fe53b6a289412cd",
    deviceHistoryId: "c760bc88-c147-5938-a589-6403d20afe8d",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 467,
    loadBCurrent: 208,
    loadBVoltage: 10,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679486249106,
    nextUpdateTime: 1679486549100,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641aee5c7fe53b6a289412d1",
    deviceHistoryId: "cc3846df-cf05-50c1-85bf-09775ca7ccbf",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 368,
    loadBCurrent: 144,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679486556010,
    nextUpdateTime: 1679486856004,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641aef8e7fe53b6a289412d5",
    deviceHistoryId: "9e07d5b5-71da-59c6-b367-339b05a9113c",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 385,
    loadBCurrent: 144,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679486862667,
    nextUpdateTime: 1679487162661,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641af0c17fe53b6a289412d9",
    deviceHistoryId: "f2f3547c-9b41-50c9-9a6e-658340916645",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 458,
    loadBCurrent: 213,
    loadBVoltage: 44,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679487169526,
    nextUpdateTime: 1679487469520,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641af1f47fe53b6a289412dd",
    deviceHistoryId: "cb8ad183-b64f-54ac-b81c-cc155e567699",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 458,
    loadBCurrent: 192,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679487476366,
    nextUpdateTime: 1679487776361,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641af3257fe53b6a289412e1",
    deviceHistoryId: "f9b93e93-43fe-5a69-84e1-9dacbaa0ae06",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 368,
    loadBCurrent: 142,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679487781407,
    nextUpdateTime: 1679488081401,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641af4587fe53b6a289412e5",
    deviceHistoryId: "e53490e3-b7fb-5dcc-b5cc-0849fc5f6d42",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 304,
    loadBCurrent: 77,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 48,
    createdAt: 1679488088204,
    nextUpdateTime: 1679488388198,
    __v: 0,
  },
  {
    batteryTemperature: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "ON",
    _id: "641af6eb7fe53b6a289412e9",
    deviceHistoryId: "57758613-db93-52d5-977f-bdba7a4d9505",
    deviceId: "A123",
    recent: false,
    pvVoltage: 0,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 0,
    bmsTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    chargeControllerTemperature: 0,
    loadACurrent: 0,
    loadAVoltage: 0,
    loadBCurrent: 0,
    loadBVoltage: 0,
    loadCCurrent: 0,
    loadCVoltage: 0,
    loadDCurrent: 0,
    loadDVoltage: 0,
    pvEnergy: 0,
    cabinetLockStatus: 0,
    createdAt: 1679488747280,
    nextUpdateTime: 1679489047273,
    __v: 0,
  },
  {
    _id: "641b130095930faf2e01748f",
    deviceHistoryId: "fb1d225e-a6ef-52a9-ad63-80b40ba2ee19",
    deviceId: "A123",
    recent: false,
    pvVoltage: 13.99,
    pvCurrent: 8.21,
    pvPower: 114.96,
    batteryVoltage: 13.02,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 114.96,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 10152,
      currentA: 4248,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b130095930faf2e017490",
    },
    createdAt: 1679495936489,
    nextUpdateTime: 1679496236489,
    __v: 0,
  },
  {
    _id: "641b142f95930faf2e017498",
    deviceHistoryId: "7f7fbca5-407b-5107-b98e-9c46371717ee",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.79,
    pvCurrent: 9.97,
    pvPower: 147.52,
    batteryVoltage: 13.66,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 147.52,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 7296,
      currentA: 1536,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b142f95930faf2e017499",
    },
    createdAt: 1679496239994,
    nextUpdateTime: 1679496539987,
    __v: 0,
  },
  {
    _id: "641b155f95930faf2e0174a9",
    deviceHistoryId: "dd2050ca-62f2-5285-90d8-31d6a193543c",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.16,
    pvCurrent: 7.93,
    pvPower: 120.33,
    batteryVoltage: 13.4,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 120.33,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9144,
      currentA: 2856,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b155f95930faf2e0174aa",
    },
    createdAt: 1679496543445,
    nextUpdateTime: 1679496843439,
    __v: 0,
  },
  {
    _id: "641b168e95930faf2e0174ae",
    deviceHistoryId: "106667b3-d149-5eaa-8479-f287cd840f2b",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.25,
    pvCurrent: 8.64,
    pvPower: 131.89,
    batteryVoltage: 13.71,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 131.89,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 10488,
      currentA: 4344,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b168e95930faf2e0174af",
    },
    createdAt: 1679496846926,
    nextUpdateTime: 1679497146920,
    __v: 0,
  },
  {
    _id: "641b17be95930faf2e0174b3",
    deviceHistoryId: "45a50133-f594-57cd-ace4-c7bf26168806",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15,
    pvCurrent: 8.44,
    pvPower: 126.73,
    batteryVoltage: 13.44,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 126.73,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8616,
      currentA: 2688,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b17be95930faf2e0174b4",
    },
    createdAt: 1679497150362,
    nextUpdateTime: 1679497450356,
    __v: 0,
  },
  {
    _id: "641b18ed95930faf2e0174b8",
    deviceHistoryId: "ca3913f4-930f-5f8d-8f5e-510495fdba4d",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.45,
    pvCurrent: 7.62,
    pvPower: 117.87,
    batteryVoltage: 13.38,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 117.87,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8760,
      currentA: 3096,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b18ed95930faf2e0174b9",
    },
    createdAt: 1679497453824,
    nextUpdateTime: 1679497753819,
    __v: 0,
  },
  {
    _id: "641b1a1d95930faf2e0174bd",
    deviceHistoryId: "d19d1e82-b0e2-53ec-ab8a-fc3fe6753ef5",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.47,
    pvCurrent: 7.35,
    pvPower: 113.73,
    batteryVoltage: 13.24,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 113.73,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9648,
      currentA: 3768,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b1a1d95930faf2e0174be",
    },
    createdAt: 1679497757323,
    nextUpdateTime: 1679498057318,
    __v: 0,
  },
  {
    _id: "641b1b4c95930faf2e0174c2",
    deviceHistoryId: "e1652a1a-efb5-5706-a2c5-5dd84ad76ebd",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.86,
    pvCurrent: 6.81,
    pvPower: 108.07,
    batteryVoltage: 13.18,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 108.07,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 7848,
      currentA: 1296,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b1b4c95930faf2e0174c3",
    },
    createdAt: 1679498060844,
    nextUpdateTime: 1679498360838,
    __v: 0,
  },
  {
    _id: "641b1c7c95930faf2e0174c7",
    deviceHistoryId: "2a790480-2d37-5a83-92dd-fccf61c91ff1",
    deviceId: "A123",
    recent: false,
    pvVoltage: 13.83,
    pvCurrent: 6.61,
    pvPower: 91.5,
    batteryVoltage: 12.87,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 91.5,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8472,
      currentA: 3120,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b1c7c95930faf2e0174c8",
    },
    createdAt: 1679498364204,
    nextUpdateTime: 1679498664199,
    __v: 0,
  },
  {
    _id: "641b1dab95930faf2e0174d0",
    deviceHistoryId: "c2c876ea-c836-509e-af83-0ce89c91d41d",
    deviceId: "A123",
    recent: false,
    pvVoltage: 13.8,
    pvCurrent: 5.09,
    pvPower: 70.3,
    batteryVoltage: 12.6,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 70.3,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8760,
      currentA: 2544,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b1dab95930faf2e0174d1",
    },
    createdAt: 1679498667645,
    nextUpdateTime: 1679498967640,
    __v: 0,
  },
  {
    _id: "641b1edb95930faf2e0174d5",
    deviceHistoryId: "1ec55909-5a7c-5537-a8a1-c971731f8380",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.36,
    pvCurrent: 7.61,
    pvPower: 109.27,
    batteryVoltage: 13.23,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 109.27,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9984,
      currentA: 3456,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b1edb95930faf2e0174d6",
    },
    createdAt: 1679498971125,
    nextUpdateTime: 1679499271118,
    __v: 0,
  },
  {
    _id: "641b200a95930faf2e0174da",
    deviceHistoryId: "2e883541-72c6-535b-a442-858d4b1dd620",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.22,
    pvCurrent: 6.88,
    pvPower: 104.79,
    batteryVoltage: 13.35,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 104.79,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9936,
      currentA: 4080,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b200a95930faf2e0174db",
    },
    createdAt: 1679499274585,
    nextUpdateTime: 1679499574580,
    __v: 0,
  },
  {
    _id: "641b213995930faf2e0174df",
    deviceHistoryId: "019d3f1e-c86e-566b-a8b4-055cfcf1c2f3",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.74,
    pvCurrent: 6.03,
    pvPower: 94.97,
    batteryVoltage: 13.32,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 94.97,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 10224,
      currentA: 4152,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b213995930faf2e0174e0",
    },
    createdAt: 1679499577965,
    nextUpdateTime: 1679499877959,
    __v: 0,
  },
  {
    _id: "641b226995930faf2e0174e4",
    deviceHistoryId: "ecac561e-6ae7-523e-9980-0448d5a61520",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.07,
    pvCurrent: 6.28,
    pvPower: 94.7,
    batteryVoltage: 13.19,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 94.7,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9792,
      currentA: 3456,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b226995930faf2e0174e5",
    },
    createdAt: 1679499881444,
    nextUpdateTime: 1679500181436,
    __v: 0,
  },
  {
    _id: "641b239895930faf2e0174e9",
    deviceHistoryId: "4b561f1f-ddd8-5797-9f34-f81a677e7dd3",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.18,
    pvCurrent: 6.42,
    pvPower: 97.6,
    batteryVoltage: 13.19,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 97.6,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 7944,
      currentA: 2208,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b239895930faf2e0174ea",
    },
    createdAt: 1679500184906,
    nextUpdateTime: 1679500484900,
    __v: 0,
  },
  {
    _id: "641b24c895930faf2e0174ee",
    deviceHistoryId: "1a53e131-a071-5765-a972-67b70f891b86",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.89,
    pvCurrent: 5.99,
    pvPower: 89.29,
    batteryVoltage: 13.17,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 89.29,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8976,
      currentA: 3000,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b24c895930faf2e0174ef",
    },
    createdAt: 1679500488429,
    nextUpdateTime: 1679500788423,
    __v: 0,
  },
  {
    _id: "641b25f795930faf2e0174f3",
    deviceHistoryId: "09d523b4-0542-50fc-8e95-15bbfd3c4e7b",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.17,
    pvCurrent: 5.78,
    pvPower: 81.97,
    batteryVoltage: 12.97,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 81.97,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 10128,
      currentA: 4488,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b25f795930faf2e0174f4",
    },
    createdAt: 1679500791506,
    nextUpdateTime: 1679501091500,
    __v: 0,
  },
  {
    _id: "641b272795930faf2e0174f8",
    deviceHistoryId: "cc939f16-fd35-53e1-b428-2758f10b3a1e",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.57,
    pvCurrent: 5.39,
    pvPower: 78.58,
    batteryVoltage: 12.99,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 78.58,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 7608,
      currentA: 1656,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b272795930faf2e0174f9",
    },
    createdAt: 1679501095285,
    nextUpdateTime: 1679501395280,
    __v: 0,
  },
  {
    _id: "641b285695930faf2e0174fd",
    deviceHistoryId: "83712a0c-c4d5-5d26-ba5f-ffef15eec7b8",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.62,
    pvCurrent: 5.01,
    pvPower: 73.34,
    batteryVoltage: 12.89,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 73.34,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9960,
      currentA: 4392,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b285695930faf2e0174fe",
    },
    createdAt: 1679501398825,
    nextUpdateTime: 1679501698818,
    __v: 0,
  },
  {
    _id: "641b298695930faf2e017506",
    deviceHistoryId: "cabb6c7e-d97c-5d00-97fc-93f8693bded5",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.6,
    pvCurrent: 4.69,
    pvPower: 68.48,
    batteryVoltage: 12.8,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 68.48,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8880,
      currentA: 2688,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b298695930faf2e017507",
    },
    createdAt: 1679501702329,
    nextUpdateTime: 1679502002323,
    __v: 0,
  },
  {
    _id: "641b2ab595930faf2e01751d",
    deviceHistoryId: "70c30113-2357-59aa-9f93-1a7ffcfa6081",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.24,
    pvCurrent: 4.37,
    pvPower: 62.34,
    batteryVoltage: 12.37,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 62.34,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8832,
      currentA: 3384,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b2ab595930faf2e01751e",
    },
    createdAt: 1679502005769,
    nextUpdateTime: 1679502305764,
    __v: 0,
  },
  {
    _id: "641b2be495930faf2e017528",
    deviceHistoryId: "7f0b9c4c-c1c6-5b9c-830c-3de7be392ee7",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.03,
    pvCurrent: 3.94,
    pvPower: 59.23,
    batteryVoltage: 12.29,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 59.23,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 10128,
      currentA: 4056,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b2be495930faf2e017529",
    },
    createdAt: 1679502308970,
    nextUpdateTime: 1679502608964,
    __v: 0,
  },
  {
    _id: "641b2d1495930faf2e017535",
    deviceHistoryId: "82d43b2f-3fee-5cd4-8617-fa1cf360f9a2",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.45,
    pvCurrent: 3.56,
    pvPower: 55.14,
    batteryVoltage: 12.42,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 55.14,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8856,
      currentA: 2808,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b2d1495930faf2e017536",
    },
    createdAt: 1679502612790,
    nextUpdateTime: 1679502912784,
    __v: 0,
  },
  {
    _id: "641b2e4495930faf2e017540",
    deviceHistoryId: "477d4571-78a3-50a8-8764-aa464fe164cc",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.17,
    pvCurrent: 3.24,
    pvPower: 49.24,
    batteryVoltage: 12.19,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 49.24,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9048,
      currentA: 2808,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b2e4495930faf2e017541",
    },
    createdAt: 1679502916288,
    nextUpdateTime: 1679503216282,
    __v: 0,
  },
  {
    _id: "641b2f7395930faf2e017555",
    deviceHistoryId: "1ec2fe2f-deb0-5790-9483-d3499d4e9d8a",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.79,
    pvCurrent: 2.84,
    pvPower: 42.14,
    batteryVoltage: 12.11,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 42.14,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9936,
      currentA: 3600,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b2f7395930faf2e017556",
    },
    createdAt: 1679503219769,
    nextUpdateTime: 1679503519763,
    __v: 0,
  },
  {
    _id: "641b30a395930faf2e01755e",
    deviceHistoryId: "13913407-6179-5387-9ff9-fcf0e0a2ed35",
    deviceId: "A123",
    recent: false,
    pvVoltage: 15.74,
    pvCurrent: 2.53,
    pvPower: 39.92,
    batteryVoltage: 12.21,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 39.92,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8952,
      currentA: 2736,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b30a395930faf2e01755f",
    },
    createdAt: 1679503523249,
    nextUpdateTime: 1679503823243,
    __v: 0,
  },
  {
    _id: "641b31d295930faf2e01757b",
    deviceHistoryId: "47db3a40-32b6-580a-92bb-24bc5d591039",
    deviceId: "A123",
    recent: false,
    pvVoltage: 14.98,
    pvCurrent: 1.98,
    pvPower: 29.67,
    batteryVoltage: 11.37,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 29.67,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9216,
      currentA: 2832,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b31d295930faf2e01757c",
    },
    createdAt: 1679503826772,
    nextUpdateTime: 1679504126766,
    __v: 0,
  },
  {
    _id: "641b330295930faf2e017588",
    deviceHistoryId: "d252845b-e4f9-553a-ab8c-17906071a212",
    deviceId: "A123",
    recent: false,
    pvVoltage: 13.46,
    pvCurrent: 0.99,
    pvPower: 13.44,
    batteryVoltage: 10.26,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 13.44,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8856,
      currentA: 3144,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b330295930faf2e017589",
    },
    createdAt: 1679504130166,
    nextUpdateTime: 1679504430160,
    __v: 0,
  },
  {
    _id: "641b343195930faf2e01758d",
    deviceHistoryId: "730417a8-44f5-5b93-8855-09a51460483a",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.26,
    pvCurrent: 0.6,
    pvPower: 6.76,
    batteryVoltage: 10.09,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 6.76,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 8832,
      currentA: 2808,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b343195930faf2e01758e",
    },
    createdAt: 1679504433689,
    nextUpdateTime: 1679504733683,
    __v: 0,
  },
  {
    _id: "641b356195930faf2e0175a4",
    deviceHistoryId: "c3853d2b-b719-5616-bd61-583b89e8b709",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.37,
    pvCurrent: 0.49,
    pvPower: 5.63,
    batteryVoltage: 10.25,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 5.63,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9168,
      currentA: 3240,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b356195930faf2e0175a5",
    },
    createdAt: 1679504737003,
    nextUpdateTime: 1679505036996,
    __v: 0,
  },
  {
    _id: "641b369095930faf2e0175bd",
    deviceHistoryId: "66be96d8-3d28-5d51-90a1-27f34db23dae",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.36,
    pvCurrent: 0.45,
    pvPower: 5.11,
    batteryVoltage: 10.23,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 5.11,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9288,
      currentA: 3792,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b369095930faf2e0175be",
    },
    createdAt: 1679505040408,
    nextUpdateTime: 1679505340402,
    __v: 0,
  },
  {
    _id: "641b37bf95930faf2e0175ca",
    deviceHistoryId: "6ab97e95-3e49-5509-98e7-b03cbc766cd9",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.47,
    pvCurrent: 0.8,
    pvPower: 9.24,
    batteryVoltage: 10.27,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 9.24,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9336,
      currentA: 3288,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b37bf95930faf2e0175cb",
    },
    createdAt: 1679505343897,
    nextUpdateTime: 1679505643891,
    __v: 0,
  },
  {
    _id: "641b38ef95930faf2e0175d1",
    deviceHistoryId: "c34a9864-0c19-5cf7-8caa-74247afa84bf",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.48,
    pvCurrent: 0.73,
    pvPower: 8.41,
    batteryVoltage: 10.26,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 8.41,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9336,
      currentA: 3888,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b38ef95930faf2e0175d2",
    },
    createdAt: 1679505647357,
    nextUpdateTime: 1679505947352,
    __v: 0,
  },
  {
    _id: "641b3a1e95930faf2e0175e0",
    deviceHistoryId: "a9230a8e-7175-5891-bcee-653c6d3681b3",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.43,
    pvCurrent: 0.62,
    pvPower: 7.16,
    batteryVoltage: 10.23,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 7.16,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9360,
      currentA: 3840,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b3a1e95930faf2e0175e1",
    },
    createdAt: 1679505950462,
    nextUpdateTime: 1679506250456,
    __v: 0,
  },
  {
    _id: "641b3b4f95930faf2e0175f9",
    deviceHistoryId: "7c2c1396-738d-5c0c-a33f-52978c00eed9",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.37,
    pvCurrent: 0.49,
    pvPower: 5.62,
    batteryVoltage: 10.22,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 5.62,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9600,
      currentA: 3504,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b3b4f95930faf2e0175fa",
    },
    createdAt: 1679506255163,
    nextUpdateTime: 1679506555155,
    __v: 0,
  },
  {
    _id: "641b3c7d95930faf2e017612",
    deviceHistoryId: "28926693-62fe-5499-80d4-24d2bc7f219c",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.31,
    pvCurrent: 0.27,
    pvPower: 3.07,
    batteryVoltage: 10.26,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 3.07,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9576,
      currentA: 3456,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b3c7d95930faf2e017613",
    },
    createdAt: 1679506557761,
    nextUpdateTime: 1679506857755,
    __v: 0,
  },
  {
    _id: "641b3dac95930faf2e017621",
    deviceHistoryId: "0261a776-c137-5b95-8b82-aab2df7d0e07",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.15,
    pvCurrent: 0.19,
    pvPower: 2.14,
    batteryVoltage: 10.23,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 2.14,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9600,
      currentA: 3504,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b3dac95930faf2e017622",
    },
    createdAt: 1679506860849,
    nextUpdateTime: 1679507160841,
    __v: 0,
  },
  {
    _id: "641b3edc95930faf2e01763a",
    deviceHistoryId: "3b940220-a31b-5f61-ba7b-ba44cc8c528b",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.03,
    pvCurrent: 0.1,
    pvPower: 1.12,
    batteryVoltage: 10.24,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 1.12,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9552,
      currentA: 3456,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b3edc95930faf2e01763b",
    },
    createdAt: 1679507164601,
    nextUpdateTime: 1679507464595,
    __v: 0,
  },
  {
    _id: "641b400c95930faf2e01764b",
    deviceHistoryId: "4fb95ce7-6803-529e-8989-f732d23f8073",
    deviceId: "A123",
    recent: false,
    pvVoltage: 11.04,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 10.24,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9600,
      currentA: 4104,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b400c95930faf2e01764c",
    },
    createdAt: 1679507468006,
    nextUpdateTime: 1679507767998,
    __v: 0,
  },
  {
    _id: "641b413a95930faf2e017654",
    deviceHistoryId: "aa366955-f871-56f6-84dc-1eb9039ee87b",
    deviceId: "A123",
    recent: true,
    pvVoltage: 10.83,
    pvCurrent: 0,
    pvPower: 0,
    batteryVoltage: 10.23,
    batteryTemperature: 0,
    batteryStateOfCharge: 0,
    powerChargeControllerToLoad: 0,
    cabinetTemperature: 0,
    loadAPower: 0,
    loadBPower: 0,
    loadCPower: 0,
    loadDPower: 0,
    pvEnergy: 0,
    doorState: "CLOSE",
    powerToTheBattery: 0,
    loadAStatus: "OFF",
    loadBStatus: "OFF",
    loadCStatus: "OFF",
    loadDStatus: "OFF",
    fanState: 0,
    siteStatus: "OFF",
    others: {
      boostAVoltage: 0,
      boostBVoltage: 9600,
      currentA: 4176,
      currentB: 0,
      currentC: 0,
      currentD: null,
      boostStatus: 0,
      _id: "641b413a95930faf2e017655",
    },
    createdAt: 1679507770994,
    nextUpdateTime: 1679508070988,
    __v: 0,
  },
];
