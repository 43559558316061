import React, { useEffect, useState } from "react";
import useQuery from "../../hooks/useQueryParam";
import useSignIn from "../../hooks/useSignIn";
import { Button, Spinner } from "../../components/shared";
import verifiedImage from "../../assets/images/email-verified.svg";
import { Link } from "react-router-dom";

const VerifyEmail = () => {
  const [verified, setVerified] = useState(false);
  let query = useQuery();
  const { isLoading, verifyEmail, resendEmail } = useSignIn();
  const token = query.get("token");

  const onVerifyEmail = async () => {
    if (token) {
      let result = await verifyEmail(token);
      setVerified(result);
    }
  };

  useEffect(() => {
    onVerifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="w-full h-screen bg-slate-200/30 flex items-center justify-center">
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
          <h5 className="font-bold text-lg md:text-xl lg:text-2xl">
            Verifying Email...
          </h5>
        </div>
      ) : (
        <div className="w-full max-w-md bg-white  m-4 md:m-4 px-6 py-10 mx-auto  rounded-lg">
          {verified ? (
            <div className="text-center">
              <div className="p-6 w-full max-w-xs mx-auto">
                <img src={verifiedImage} alt="checkmark"  className="w-full"/>
              </div>
              <h4 className="text-center font-semibold text-xl md:text-2xl lg:text-4xl mb-8">
                Email Verified
              </h4>
              <div className="text-center">
                <Link to="/login">
                  <Button className="px-4 py-2 text-center">Go to Login</Button>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <h4 className="text-center font-semibold text-xl md:text-2xl lg:text-4xl mb-8">
                Unable to Verify email
              </h4>
              <div className="text-center">
                <Button
                  className="px-4 py-2 text-center"
                  onClick={() => resendEmail()}
                >
                  Resend everification email
                </Button>
              </div>
              <div className="text-center mt-8">
                <Link to="/login">
                  <Button className="px-4 py-2 text-center">
                    Back to Login
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
