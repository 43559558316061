import React, { forwardRef } from "react";

const Button = forwardRef(function Button(
  {
    children,
    type,
    id,
    disabled,
    value,
    additionalFunc,
    onClick,
    onKeyDown,
    onKeyUp,
    className,
    error,
    ...props
  },
  ref
) {
  return (
    <React.Fragment>
      <button
        className={` text-white rounded-xl  py-3  ${
          disabled ? "bg-darkTeal/40" : "bg-darkTeal"
        }  ${className}`}
        type={type}
        id={id}
        ref={ref}
        disabled={disabled}
        onClick={(e) => {
          onClick && onClick(e);
          additionalFunc && additionalFunc(e);
        }}
        onKeyDown={onKeyDown}
        value={value}
        onKeyUp={onKeyUp}
        {...props}
      >
        {children}
      </button>
    </React.Fragment>
  );
});

export default Button;

// const Input = forwardRef(function Input(
//   {
//     type,
//     placeholder,
//     name,
//     id,
//     disabled,
//     value,
//     additionalFunc,
//     onChange,
//     onKeyDown,
//     onKeyUp,
//     className,
//     error,
//     ...props
//   },
//   ref
// ) {
//   return (
//     <React.Fragment>
//       <input
//         className={`bg-input w-full py-3 px-4 ${
//           error ? "focus:outline-red" : "focus:outline-teal1"
//         }  focus:bg-transparent ${className}`}
//         type={type}
//         placeholder={placeholder}
//         id={id}
//         name={name}
//         ref={ref}
//         disabled={disabled}
//         onChange={(e) => {
//           onChange(e);
//           additionalFunc && additionalFunc(e);
//         }}
//         onKeyDown={onKeyDown}
//         value={value}
//         onKeyUp={onKeyUp}
//         {...props}
//       />
//     </React.Fragment>
//   );
// });
// export default Input;
