import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LogoTransparent } from "../../assets/images";
import { AlertIcon, MobileNavIcon, LogoutIcon } from "../../assets/icons";
import { sidebaritems,ownersidebaritems } from "../../data";
import { SidebarItem } from "./Sidebar";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import useLogOut from "../../hooks/useLogOut";
import { HasAccess } from "@permify/react-role";
import { useAuthContext } from "../../hooks/useAuthContext";

const Navbar = () => {
  const location = useLocation();
  const [title, setTitle] = useState(null);
  const {
    user: { _user },
  } = useAuthContext();
  const makeTitle = () => {
    // console.log(location.pathname);
    switch (location.pathname) {
      case "/sites":
        setTitle("Sites Overview");
        break;
      case "/management":
        setTitle("Management Overview");
        break;
      case "/devices":
        setTitle("Device Overview");
        break;
      case "/alarms":
        setTitle("Alarm Overview");
        break;
      case "/companies":
        setTitle("Company Overview");
        break;
      default:
        return null;
    }
  };
  const [isExpanded, setIsExpanded] = useState();
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };
  const closeSidebar = () => {
    setIsExpanded(false);
  };
  let pieceA = _user?.firstName?.split("")[0] ?? "";
  let pieceB = _user?.lastName?.split("")[0] ?? "";
  const ref = useOutsideClick(closeSidebar);
  const { logout } = useLogOut();
  const onLogout = () => {
    logout();
  };

  const company = JSON.parse(localStorage.getItem("company")) ?? {};

  useEffect(() => {
    makeTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div
      ref={ref}
      className="fixed top-0 left-0 right-0 bg-darkTeal w-full px-5 py-4 text-white z-[100]"
    >
      <div className="flex items-center justify-between">
        <div className="">
          <img
            src={company?.companyLogo ? company.companyLogo : LogoTransparent}
            alt="logo"
            className="w-32 md:w-36 h-14 max-h-14"
          />
        </div>

        <div className="lg:hidden">
          <img src={MobileNavIcon} alt="logo" onClick={toggleSidebar} />
        </div>

        <div className="hidden lg:block">
          {title ? (
            <div className="bg-[#13617F] py-2 px-6 rounded-md f">
              <span className="font-medium text-sm lg:text-lg">{title}</span>
            </div>
          ) : null}
        </div>

        <div className=" hidden lg:flex items-center justify-between">
          <div className="bg-[#13617F] p-2 rounded-md mr-16">
            <img src={AlertIcon} alt="alert" className="w-8 h-8" />
          </div>

          <div className="flex items-center mr-5">
            <div className="flex flex-col justify-end text-sm text-white mr-3">
              <p className="text-end ">
                {`${_user?.firstName ? _user.firstName : ""}  ${
                  _user?.lastName ? _user.lastName : ""
                }`}
              </p>
              {/* <p className="text-end">Admin</p> */}
            </div>

            <div className="bg-[#13617F] p-2 rounded-md text-[30px] text-white font-semibold leading-[24px] uppercase">
              <span className=" ">{pieceA}</span>
              <span className="">{pieceB}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`lg:hidden sidebar ${isExpanded ? "block" : "hidden"}`}>
        <div className="  h-full  flex flex-col justify-between ">
        {_user?.role === "owner" ? (
            <div className="mb-12">
              {ownersidebaritems.map((item, i) => (
                <HasAccess
                  key={`djkbh${i}`}
                  // roles={"owner"}
                  permissions={item.permissions}
                  renderAuthFailed={null}
                  // isLoading={<Spinner/>}
                >
                  <SidebarItem item={item} />
                </HasAccess>
              ))}
            </div>
          ) : (
            <div className="mb-12">
              {sidebaritems.map((item, i) => (
                <HasAccess
                  key={`djkbh${i}`}
                  // roles={"owner"}
                  permissions={item.permissions}
                  renderAuthFailed={null}
                  // isLoading={<Spinner/>}
                >
                  <SidebarItem item={item} />
                </HasAccess>
              ))}
            </div>
          )}

          <div className="">
            <div className="flex items-center mb-4 px-6 " onClick={onLogout}>
              <img src={LogoutIcon} alt="home" className=" w-5 h-5 mr-4" />
              <span className="font-semibold text-lg">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
