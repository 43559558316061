import React from "react";

const Modal = ({ closeModal, title, children }) => {
  const allowClick = (ev) => {
    ev.stopPropagation();
  };
  return (
    <div className="modal-overlay">
      <div className="modal-centered">
        <div className="modal-wrapper" onClick={allowClick}>
          <div className="modal-content-wrapper">
            <div className="modal-header">
              <h5 className="font-semibold text-base md:text-lg lg:text-xl xl:text-2xl">
                {title}
              </h5>

              <span className="modal-closeBtn" onClick={closeModal}>
                X
              </span>
            </div>
            <div className="my-3">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
