// import { ReactComponent as Search } from "../../assets/icons/search-input-icon.svg";
import { forwardRef } from "react";

const SearchInput = forwardRef(function SearchInput(
  {
    options,
    bordered,
    type,
    placeholder,
    name,
    id,
    disabled,
    className,
    value,
    paddingX,
    paddingY,
    // additionalFunc,
    onChange,
    fontSize,
    onKeyDown,
    onKeyUp,
    ...props
  },
  ref
) {
  return (
    <>
      <select
        id={id}
        name={name}
        ref={ref}
        disabled={disabled}
        onChange={(e) => {
          onChange(e);
          // additionalFunc && additionalFunc(e);
        }}
        onKeyDown={onKeyDown}
        value={value}
        onKeyUp={onKeyUp}
        {...props}
        className={`bg-input   focus:outline-none w-full  pr-6  ${
          bordered ? "border border-teal1" : "border-0"
        } ${paddingY ? paddingY : "py-3"}
        ${paddingX ? paddingX : "px-4"}
        ${fontSize ? fontSize : "text-sm"}
        ${className}`}
      >
        <option className="!text-grey3">{placeholder}</option>
        {options
          ? options.map((item, i) => <option key={i}>{item}</option>)
          : null}
      </select>
    </>
  );
});
export default SearchInput;
