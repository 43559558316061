import React from "react";
import { FooterContent } from "../shared";
import { Logo, AuthImage } from "../../assets/images";
const AuthLayout = ({ children, title, subtitle }) => {
  return (
    <div className="grid grid-cols-12 gap-12 h-[100vh] min-h-fit px-6 py-7">
      <div className=" hidden lg:block lg:col-span-6   ">
        <img src={AuthImage} alt="" className="w-full " />
      </div>

      <div className=" col-span-12 lg:col-span-6  px-4 flex flex-col justify-between items-center  h-full">
        <div className="py-6">
          <img src={Logo} alt="logo" className="w-40 h-12" />
        </div>

        <div className="border border-borderGrey px-7 py-14 w-full lg:w-11/12 xl:w-9/12 mx-auto min-h-[400px] rounded-xl flex flex-col justify-center">
          <div className="">
            <h4 className="font-semibold text-3xl text-center mb-5">{title}</h4>

            <p className="font-semibold text-grey1 text-xl text-center ">
              {subtitle}
            </p>
            <div className="">{children}</div>
          </div>
        </div>
        <div className="my-5">
          <FooterContent blue />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
