import React, { useEffect, useState } from "react";
import ReactSwitch from "react-switch";

const ToggleSwitch = ({ isChecked, disabled, onChange, index }) => {
  const [checked, setChecked] = useState(isChecked);
  const handleChange = (value) => {
    setChecked(value);
    onChange({ value, index });
  };
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div>
      <label>
        <ReactSwitch
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          className="react-switch"
          checkedIcon={
            <div className="">
              <span className="text-white text-xxs">online</span>
            </div>
          }
          uncheckedIcon={
            <div className="">
              <span className="text-white text-xxs">offline</span>
            </div>
          }
          onHandleColor="#FFFFFF"
          offHandleColor="#FFFFFF"
          handleDiameter={20}
          width={60}
          height={24}
          offColor="#EE1B25"
          onColor="#25C348"
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
