import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ total, paginate,  selected }) => {
  return (
    <div className="my-4">
      <ReactPaginate
        containerClassName="flex items-center text-sm"
        pageClassName="px-3 py-2"
        activeClassName="bg-darkTeal  text-white rounded-md"
        previousLinkClassName={` py-1 px-3 text-sm mr-2 text-darkTeal  ${
          selected <= 1 ? " text-borderGrey" : ""
        }`}
        nextLinkClassName={`  py-1 px-3 text-sm ml-2 text-darkTeal ${
          selected  >= total ? " text-borderGrey " : ""
        }`}
        breakLabel="..."
        nextLabel="Next >"
        onPageChange={paginate}
        pageRangeDisplayed={10}
        pageCount={total}
        previousLabel="< Prev"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Pagination;
