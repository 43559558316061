import React from "react";
import { Input } from "../../components/shared";
import { EmailIcon } from "../../assets/icons";

const ForgotPassword = () => {
  return (
    <div className="mt-5 ">
      <div className="mb-4 relative ">
        <img
          src={EmailIcon}
          alt=""
          className="w-5 h-5 absolute left-3 top-1/3 "
        />
        <Input placeholder=" " className="rounded-xl pl-12" />
        <label for="email" className=" custom-label ">Email</label>
      </div>

      <div className="text-center mt-8">
        <button className="bg-teal1 text-white text-2xl w-4/5 mx-auto py-3 rounded-xl">
          Reset password
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
